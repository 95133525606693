<template>
  <AppPageCard>
    <template #card-title> Away Assignment </template>
    <template #card-toolbar>
      <!-- begin: refresh button  -->
      <b-button
        variant="primary"
        class="btn btn-icon btn-light btn-hover-primary ml-4"
        v-on:click="refresh"
      >
        <span class="svg-icon svg-icon-2x svg-icon-primary">
          <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
        </span>
      </b-button>
      <!-- end: refresh button  -->
    </template>
    <CompanyAwayEntitlementTable
      :items="items"
      :is-busy="isBusy"
      :pagination="pagination"
      @navigateToIndividual="goToEmployeeAssignment"
    ></CompanyAwayEntitlementTable>
  </AppPageCard>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import { mapGetters } from "vuex";
import CompanyAwayEntitlementTable from "@/modules/company/components/away/entitlement/CompanyAwayEntitlementTable";
export default {
  name: "EmployeeAwayAssignment",
  components: { CompanyAwayEntitlementTable, AppPageCard },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  beforeDestroy() {
    this.$store.commit("setAssignedEmployeeListForLeave", []);
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchAssignedEmployeeListForLeave")
        .finally(() => (this.isBusy = false));
    },

    /**
     * Navigate to Teacher individual entitlement list
     *
     * @param data
     */
    goToEmployeeAssignment(data) {
      console.log(data);
      this.$router.push({
        name: "employeeIndividualAwayAssignment",
        query: {
          id: data.item.id,
          name: `${data.item.first_name} ${data.item.last_name}`,
        },
      });
    },

    refresh() {
      this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      items: "getAssignedCompanyLeaveEntitlementList",
      pagination: "getAssignedCompanyLeaveEntitlementListPagination",
    }),
  },
};
</script>

<style scoped></style>
